export const TRANSLATION_EN_GB = {
  HOW_IT_WORKS: "How it works",
  TOKEN: "Token",
  TEAM: "Team",
  GET_EARLY_ACCESS: "Get Early Access",
  DISCOVER_KUROBI: "Discover Kurobi",
  GET_CLOSER: "Get close to your",
  AUDIENCE: "audience",
  AND: "and",
  MONITIZE: "monetize",
  YOUR_TIME_SOLANA_BLOCKCHAIN: "your time powered by Solana blockchain",
  ACCEPT_BOOKING:
    "Accept booking in your free time, sell NFT tickets to live events and get paid anywhere in the world on Kurobi",
  LAUNCH_APP: "Launch App",
  EARLY_ACCESS: "Early Access",
  KUROBI_ANNOUNCEMENT: "Latest Kurobi Announcement",
  TECHNOLOGIES: "Technologies",
  TAKE_YOUR_TIME_WITH_NFT_TICKETS:
    "Take your next live event up a notch with NFT tickets",
  KUROBI_NFT_TIMEPASS:
    "Kurobi’s NFT Timepass allows you to amplify your earning potential by selling NFT Tickets to your followers. Why blend in, when you can bring something different that thrills your fans?",
  JENNY_POLSK: "Jenny Polsk",
  EVERYONE_COOKING: "Everyone Cooking: Lets get everyone cooking?",
  FORTY_FIVE_MINS: "45 Mins",
  FORTY_FIVE_DOLLARS: "$45.00",
  BOOK: "Book",
  SHARE: "Share",
  CREATE_LINK: "Create a Call Link",
  SHARE_LINK: "Share your link",
  GET_PAID: "Get Paid",
  CREATE: "Create a",
  CALL_LINK: "Call Link",
  UPLOAD_VIDEO:
    "Upload a video describing what you`d like to talk about and set your preferred availability. Kurobi guarantees that you only receive bookings when you are free.",
  GET_STARTED: "Get Started",
  SHARE_YOUR: "Share Your",
  LINK: "Link",
  PUBLISH_TEXT:
    "Once published, your call link is made accessible to your audience on their preferred devices. Whether on a PC, Macbook, Android, or iOS, Kurobi is available where ever your viewers are.",
  SET_RATES:
    "Set your rates and connect your wallet to receive payments instantly through KURO, USDC, or SOL tokens.",
  GROW: "Grow",
  YOUR: "your",
  GET_STARTED_FOOTER_TEXT:
    "Are you a consultant, doctor, teacher, fitness instructor, life coach, therapist, or influencer? Reach a global audience, earn money for your time, and get paid instantly wherever you are with Kurobi. Join our blockchain-powered app today. Get started in 3 easy steps",
  RIGHTS_RESERVED: "All Rights Reserved",
  TERMS: "Terms",
  ROADMAP: "Roadmap",
  PRIVACY: "Privacy",
};
