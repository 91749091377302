import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { TRANSLATION_EN_GB } from '../translations/en'
import { TRANSLATION_ZH } from '../translations/chinese'
import { TRANSLATION_JA } from '../translations/japanese'

const locale = window.navigator.language
const lsLocale = localStorage.getItem('languagelocale')

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATION_EN_GB,
      },
      zh: {
        translation: TRANSLATION_ZH,
      },
      ja: { translation: TRANSLATION_JA },
    },
  })

i18n.changeLanguage(
  lsLocale
    ? lsLocale
    : locale.includes('zh')
    ? 'zh'
    : locale.includes('ja') || locale.includes('jp')
    ? 'ja'
    : 'en',
)
