import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import AppRoutes from "./router";

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider
      preventDuplicate
      maxSnack={1}
      ref={notistackRef}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      action={(key) => (
        <button
          className="btn"
          style={{ color: "white" }}
          onClick={onClickDismiss(key)}
        >
          X
        </button>
      )}
    >
      <AppRoutes />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
