import React from "react";
import "./register.scss";
import validator from "validator";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import KurobiLogo from "./assets/logo.svg";
import CardPhone from "./assets/iphone.png";
import { db } from "./config";
import CircularProgress from "@material-ui/core/CircularProgress";

async function alert(props, email, setLoading) {
  const validateMail = validator.isEmail(email);
  if (!validateMail) {
    props.enqueueSnackbar("Invalid Email, please enter a correct one.", {
      variant: "error",
    });
    setLoading(false);
  } else {
    try {
      const value = email.toLowerCase();
      let key = value.replace(/@/g, "_");
      key = key.replace(/\./g, "_");
      await db
        .collection("emails")
        .doc(key)
        .set({ email: value, createdAt: new Date() });
      props.enqueueSnackbar("Successful.", {
        variant: "success",
      });
      setTimeout(function () {
        window.history.back();
      }, 2000);
    } catch (e) {
      setLoading(false);
      props.enqueueSnackbar("Error. Cannot register your email.", {
        variant: "error",
      });
      console.log(e);
    }
  }
}

const Register = (props) => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  return (
    <div className="register">
      <div className="register__logo">
        <center>
          <div className="">
            <Link to="/"><img src={KurobiLogo} alt="kurobi-logo" /></Link>
          </div>
        </center>
      </div>
      <center>
        <h2>Get Early Access to scheduling calls with your audience.</h2>
        <p>
          Start earning now with{" "}
          <span style={{ opacity: 1 }}>crypto</span> 
        </p>
        <div className="registration-section">
          <input
            className="form-control"
            placeholder="Enter your email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          ></input>
          <button
            className="btn btn-primary"
            onClick={() => {
              setLoading(true);
              alert(props, email, setLoading);
            }}
          >
            {loading ? (
              <CircularProgress className="loader" />
            ) : (
                "Register to Begin !"
              )}
          </button>
        </div>
      </center>
      <div className="register__illustration">
        <center>
          <div className="">
            <img src={CardPhone} alt="phone" style={{ height: '600px' }} />
          </div>
        </center>
      </div>
    </div>
  );
};

export default withSnackbar(Register);