import React from "react";
import { withSnackbar } from "notistack";
import "./style.scss";
import "./roadmap.scss";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import RoadmapBannerLeft from "./assets/roadmap-banner-left.svg";
import RoadmapBannerRight from "./assets/roadmap-banner-right.svg";
import RoadmapBannerMoon from "./assets/roadmap-banner-moon.svg";
import RoadmapBannerSky from "./assets/roadmap-banner-sky.svg";
import RoadmapBannerTracks from "./assets/roadmap-banner-tracks.svg";
// import RoadmapBannerSkyTwo from "";
// import RoadmapBannerSkyThree from "";

const RoadMap = (props) => {
  return (
    <>
      <main className="kurobi-roadmap">
        <Header />
        <section className="kurobi-roadmap__hero">
          <img src={RoadmapBannerLeft} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-one d-none d-lg-flex"/>
          <img src={RoadmapBannerRight} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-two d-none d-lg-flex"/>
          <img src={RoadmapBannerMoon} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-three d-none d-lg-flex"/>
          <img src={RoadmapBannerSky} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-four d-none d-lg-flex"/>
          <img src={RoadmapBannerSky} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-five d-none d-lg-flex"/>
          <img src={RoadmapBannerSky} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-six d-none d-lg-flex"/>
          <img src={RoadmapBannerTracks} alt="" className="kurobi-roadmap__hero__image kurobi-roadmap__hero__image-seven d-none d-lg-flex"/>
          <div className="container">
            <div className="row justify-content-center align-items-start align-items-lg-center">
              <div className="col-sm-9">
                <div className="kurobi-roadmap__hero__text d-flex flex-column  align-items-start align-items-lg-center text-lg-center">
                  <h5>WHAT TO EXPECT IN 2022</h5>
                  <h3>A year with much to offer</h3>
                  <h6>
                    The launch of <span>NFT TimePass </span> ticketing solution, <span>Binance Pay </span>
                    Integration and much more is coming your way.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="kurobi-roadmap__content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="kurobi-roadmap__content__wrap">
                  <div className="kurobi-roadmap__content__text">
                  <header className="kurobi-roadmap__content__text__title">
                    <h2>Q4 2021</h2>
                  </header>
                  <div className="kurobi-roadmap__content__text__list">
                    <ul>
                      <li className="complete">
                        <p>Implement basic 1:1 video calling</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="kurobi-roadmap__content__text">
                  <header className="kurobi-roadmap__content__text__title">
                    <h2>Q1 2022</h2>
                  </header>
                  <div className="kurobi-roadmap__content__text__list">
                    <ul>
                      <li className="complete">
                        <p>
                          Integration of $KURO with FTXPay
                        </p>
                      </li>
                      <li className="complete">
                        <p>
                          Integration of $KURO to Transak
                        </p>
                      </li>
                      <li className="complete">
                        <p>
                          Add multi-chain protocol support for $KURO
                        </p>
                      </li>
                      <li className="complete">
                        <p>Publish updated Tokenomics</p>
                      </li>
                      <li>
                        <p>
                          Publish 2022 Roadmap
                        </p>
                      </li>
                      <li>
                        <p>
                          Community announcement for upcoming NFT Timepass product development
                        </p>
                      </li>
                      <li>
                        <p>
                          Community announcement for $KURO multi-chain support
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="kurobi-roadmap__content__text">
                  <header className="kurobi-roadmap__content__text__title">
                    <h2>Q2 2022</h2>
                  </header>
                  <div className="kurobi-roadmap__content__text__list">
                    <ul>
                      <li>
                        <p>
                          Build NFT Timepass ticketing solution for virtual event
                        </p>
                      </li>
                      <li>
                        <p>
Build & Integrate 1:1 Events discovery marketplace into Kurobi website</p>
                      </li>
                      <li>
                        <p>
                          Host AMAs, Twitter Spaces & Interviews with Influencers, to drive awareness for the NFT Timepass product launch
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="kurobi-roadmap__content__text">
                  <header className="kurobi-roadmap__content__text__title">
                    <h2>Q3 2022</h2>
                  </header>
                  <div className="kurobi-roadmap__content__text__list">
                    <ul>
                      <li>
                        <p>Partnership with Creative Agencies & Creators</p>
                      </li>
                      <li>
                        <p>
                          Complete BETA testing and prepare for Kurobi App
                          launch
                        </p>
                      </li>
                      <li>
                        <p>
                          Host Interviews with Influencers, and podcasts to drive awareness for the App launch
                        </p>
                      </li>
                      <li>
                        <p>Press releases for pre-launch hype</p>
                      </li>
                      <li>
                        <p>Launch Kurobi App</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="kurobi-roadmap__content__text">
                  <header className="kurobi-roadmap__content__text__title">
                    <h2>Q4 2022</h2>
                  </header>
                  <div className="kurobi-roadmap__content__text__list">
                    <ul>
                      <li>
                        <p>Implement the DAO system for $KURO</p>
                      </li>
                      <li>
                        <p>Integration of $KURO with Binance Pay</p>
                      </li>
                      <li>
                        <p>Swap Wormhole $KURO to native EVM $KURO</p>
                      </li>
                    </ul>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default withSnackbar(RoadMap);
