import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    useLocation,
    withRouter
} from 'react-router-dom';
import KurobiLanding from "./App";
import Register from "./register";
import RoadMap from "./roadmap";
// import Team from "./team";
import Terms from "./terms";
import Privacy from "./privacy";
import Token from "./token";
import TimePass from "./timepass";

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
      <Route path="/" component={KurobiLanding} exact />
      <Route path="/register" component={Register} />
      {/* <Route path="/team" component={Team} /> */}
      <Route path="/roadmap" component={RoadMap} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/terms" component={Terms} />
      <Route path="/token" component={Token} />
      <Route path="/timepass" component={TimePass} />
      </ScrollToTop>
    </Router>
  );
};

export default AppRoutes;
