import React from 'react'
import './style.scss'
import Header from './components/header/header'
import Footer from './components/footer/footer'
import Banner from './assets/banner.svg'
import WalletFeat from './assets/wallet-feat.svg'
import Spark from './assets/link-spark.svg'
import Plane from './assets/plane-alt.svg'
// import Crypto from "./assets/crypto.svg";
// import CoinPayment from "./assets/coinpayment.svg";
// import Stripe from "./assets/stripe.svg";
import Transak from './assets/transak.svg'
import Sol from './assets/sol.svg'
import Torus from './assets/torus.svg'
import Genesys from './assets/genesys.png'
// import Wise from "./assets/wise.svg";
// import Blocknative from "./assets/blocknative.svg";
// import { Link } from "react-router-dom";
import SubBanner from './assets/sub-banner.png'
import LadyVideo from './assets/lady-present.mp4'
import LadyCooking from './assets/cooking.mp4'
import LadyHand from './assets/lady-hand.png'
import LadyFinger from './assets/lady-fingers.png'
import BrowserImage from './assets/Browser.svg'
import PaymentCard from './assets/payment-card.svg'
import PaymentSlip from './assets/payment-slip.svg'
import BannerCall from './assets/banner-call.jpg'
import LadyPhone from './assets/lady-phone.png'
import Payment from './assets/payment.svg'
import Book from './assets/book.svg'
import Play from './assets/play.svg'
import ShieldFront from './assets/shield-front.svg'
import ShieldBack from './assets/shield-back.svg'
import VideoDot from './assets/video-dots.svg'
import VideoScatter from './assets/video-scatter.svg'
import LadyThumbnail from './assets/smile.jpg'
// import Chat from "./assets/chat.svg"
import Face from './assets/face.jpg'
import StoreButtons from './components/storebuttons/store-buttons'
import { withSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import './translations/i18n'

const language = window.navigator.language
const locale = language.includes('zh')
  ? 'zh'
  : language.includes('ja') || language.includes('jp')
  ? 'ja'
  : 'en'
const lsLocale = localStorage.getItem('languagelocale')

const setLocaleToLS = (locale) => {
  localStorage.setItem('languagelocale', locale)
  window.location.reload()
}

const Video = () => {
  return <video autoPlay playsInline loop muted src={LadyVideo} />
}

const VideoAlt = () => {
  return <video autoPlay playsInline loop muted src={LadyCooking} />
}

function KurobiLanding() {
  const { t } = useTranslation()
  return (
    <main className={`landing-page ${lsLocale || locale}`}>
      <Header />
      <section className="section-one">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 col-xl-5">
              <div className="section-one-text">
                <h6>{t('DISCOVER_KUROBI')}</h6>
                <h1 className="animated-banner-header-text">
                  {t('GET_CLOSER')} <strong>{t('AUDIENCE')}</strong> {t('AND')}{' '}
                  <strong>{t('MONITIZE')}</strong>{' '}
                  {t('YOUR_TIME_SOLANA_BLOCKCHAIN')}
                </h1>
                <span className="kurobi-text animated-banner-body-text">
                  {t('ACCEPT_BOOKING')}
                </span>
                <div className="d-flex">
                  {/* <Link
                    to="/register"
                    className="launch-button"
                  >
                    Get Started <i className="fas fa-chevron-right"></i>
                  </Link> */}
                  <a
                    href="https://beta.web.kurobi.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="launch-button"
                      style={{ marginRight: '25px' }}
                    >
                      {' '}
                      {t('LAUNCH_APP')} <i className="fas fa-chevron-right"></i>
                    </button>
                  </a>
                  <a
                    href="https://beta.web.kurobi.app/"
                    target="_blank"
                    rel="noreferrer"
                    className="launch"
                  >
                    <button
                      className="launch-button"
                      style={{
                        background: 'transparent',
                        border: '1px solid #0e1219',
                        color: '#0e1219',
                      }}
                    >
                      {' '}
                      {t('EARLY_ACCESS')}{' '}
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          background: 'rgb(255, 248, 231)',
                          color: '#0e1219',
                        }}
                      ></i>
                    </button>
                  </a>
                </div>
                <p style={{}} className="announcement-slab">
                  <a
                    href="https://kurobi.medium.com/" target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fas fa-chevron-right"></i>{' '}
                    {t('KUROBI_ANNOUNCEMENT')}{' '}
                    <span>
                      🚀 <span className="d-none d-lg-inline-block">🚀 🚀</span>
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-7">
              <div className="section-one-image d-flex d-md-none">
                {/* <img src={Chat} alt="banner" className="section-one-image__chat" /> */}
                <figure className="section-one-video">
                  <img src={LadyThumbnail} alt="lady" className="placeholder" />
                  <Video />
                  {/* <div className="section-one-video__overlay"></div> */}
                </figure>
                <img src={Banner} alt="banner" className="banner-image" />
                <img
                  src={Payment}
                  alt="banner"
                  className="section-one-image__payment d-none d-lg-block"
                />
                <img
                  src={Book}
                  alt="banner"
                  className="section-one-image__book d-none d-lg-block"
                />
              </div>

              <div className="banner-video d-none d-md-flex">
                <img
                  src={Payment}
                  alt="payment"
                  className="banner-video__decor banner-video__decor__payment"
                />
                <img
                  src={Book}
                  alt="booking"
                  className="banner-video__decor banner-video__decor__book"
                />
                <img
                  src={Play}
                  alt="play"
                  className="banner-video__decor banner-video__decor__play"
                />
                <img
                  src={VideoDot}
                  alt="decorative"
                  className="banner-video__decor banner-video__decor__dot"
                />
                <img
                  src={ShieldFront}
                  alt="shield"
                  className="banner-video__decor banner-video__decor__shield-front"
                />
                <img
                  src={ShieldBack}
                  alt="shield"
                  className="banner-video__decor banner-video__decor__shield-back"
                />

                <div className="banner-video__wrap">
                  <div className="banner-video__screen">
                    <Video />
                    <img src={LadyThumbnail} alt="lady" />
                    <div className="banner-video__screen__overlay"></div>
                  </div>
                  <div className="banner-video__thumbnails">
                    <div className="banner-video__thumbnails__item">
                      <img src={LadyThumbnail} alt="lady" />
                    </div>
                    <div className="banner-video__thumbnails__item">
                      <img src={Face} alt="lady" />
                    </div>
                  </div>
                </div>

                <img
                  src={VideoScatter}
                  alt="banner"
                  className="banner-video__decor banner-video__decor__scatter"
                />
                <img
                  src={VideoScatter}
                  alt="banner"
                  className="banner-video__decor banner-video__decor__scatter-alt"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-none d-md-flex col-md-12">
              <div className="section-one-partners">
                <div className="section-one-partners__header">
                  <h6 style={{ color: '#17173a', marginBottom: '2rem' }}>
                    {t('TECHNOLOGIES')}
                  </h6>
                </div>
                <ul>
                  <li>
                    <img src={Transak} alt="partners-transak" />
                  </li>
                  <li>
                    <img
                      src={Torus}
                      alt="partners-torus"
                      style={{ maxWidth: '105px' }}
                    />
                  </li>
                  <li>
                    <img src={Sol} alt="partners-sol" />
                  </li>
                  <li>
                    <img src={Genesys} alt="partners-Genesys" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="works section-two">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="word-mark" id="work">
                <center>
                  <h2>{t('TAKE_YOUR_TIME_WITH_NFT_TICKETS')}</h2>
                  <h6>{t('KUROBI_NFT_TIMEPASS')}</h6>
                </center>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row sub one justify-content-center align-items-center gx-5">
            <div className="col-12 col-md-8 col-lg-6 side d-flex justify-content-center">
              <div className="feat-wrap">
                <div className="poster-one d-none d-lg-block">
                  <img src={LadyPhone} alt="phone in hand" />
                </div>
                <div style={{ position: 'absolute' }}>
                  <div className="features-image-wrap">
                    <div className="features-image">
                      <figure className="features-video">
                        <VideoAlt />
                        <div className="features-video__overlay"></div>
                      </figure>
                      {/* <img
                        src={CardOne}
                        alt="card-one"
                        className="img-fluid card-img one"
                      /> */}
                    </div>
                    <div className="features-words">
                      <div className="features-words-user">
                        <div className="features-words-user-avatar"></div>
                        <p className="features-words-user-name">
                          {t('JENNY_POLSK')}
                        </p>
                      </div>
                      <h3>{t('EVERYONE_COOKING')}</h3>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '.8rem',
                        }}
                      >
                        <p>{t('FORTY_FIVE_MINS')}</p>{' '}
                        <p style={{ marginTop: '-.3rem' }}>.</p>{' '}
                        <p>{t('FORTY_FIVE_DOLLARS')}</p>
                      </div>
                      <div className="features-words-button">
                        <button className="main">
                          <i
                            className="far fa-clock"
                            style={{ opacity: '.6' }}
                          ></i>{' '}
                          {t('BOOK')}
                        </button>
                        <button className="outline">
                          <i
                            className="fas fa-share-alt"
                            style={{ opacity: '.8' }}
                          ></i>{' '}
                          {t('SHARE')}
                        </button>
                      </div>
                      <div className="link">
                        <div
                          className=""
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              marginBottom: '0',
                              whiteSpace: 'noWrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            https://kurobi.app/call_links/-MTt...
                          </p>{' '}
                          <i
                            className="far fa-copy"
                            style={{ opacity: '.6' }}
                          ></i>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="poster-two d-none d-lg-block">
                  <img src={BannerCall} alt="features" />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-5 call-link one">
              <ul className="d-none d-lg-block landing-steps">
                <li className="Active"> 👉 {t('CREATE_LINK')}</li>
                <li className="faded">{t('SHARE_LINK')}</li>
                <li className="faded">{t('GET_PAID')}</li>
              </ul>
              <div className="landing-steps__one landing-steps__paragraph">
                <i>
                  <img src={Spark} alt="spark" />
                </i>
                <h2>
                  {t('CREATE')} <br className="d-none d-lg-flex" />{' '}
                  {t('CALL_LINK')}
                </h2>
                <p>{t('UPLOAD_VIDEO')}</p>
                {/* <Link
                    to="/register"
                    className="btn started-btn"
                  >
                     Get Started <i className="fas fa-chevron-right"></i>
                  </Link> */}
                <a
                  href="https://beta.web.kurobi.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn started-btn"
                >
                  {t('GET_STARTED')} <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="works section-three">
        <div className="container">
          <div className="row sub two justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2">
              <div className="features-image">
                <img
                  src={BrowserImage}
                  alt="card-two"
                  className="img-fluid card-img"
                />
              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-5 side call-link order-2 order-md-1">
              <ul className="d-none d-lg-block landing-steps">
                <li className="complete faded">{t('CREATE_LINK')} 🎉</li>
                <li className="Active"> 👉 {t('SHARE_LINK')}</li>
                <li className="faded">{t('GET_PAID')}</li>
              </ul>

              <div className="landing-steps__paragraph">
                <i className="alt">
                  <img src={Plane} alt="plane" />
                </i>
                <h2>
                  {t('SHARE_YOUR')} <br className="d-none d-lg-flex" />{' '}
                  {t('LINK')}
                </h2>
                <p>{t('PUBLISH_TEXT')}</p>
                {/* <Link
                    to="/register"
                    className="btn started-btn"
                  >
                     Get Started <i className="fas fa-chevron-right"></i>
                  </Link> */}
                <a
                  href="https://beta.web.kurobi.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn started-btn"
                >
                  {t('GET_STARTED')} <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="works section-four">
        <div className="container">
          <div className="row sub justify-content-center align-items-center gx-5">
            <div className="col-12 col-md-7 col-lg-6 side d-flex justify-content-center order-2 order-lg-1">
              <div className="feat-wrap">
                <div className="payment-slip-wrap">
                  <img
                    src={PaymentSlip}
                    alt="payment"
                    className="payment-slip"
                  />
                </div>
                <div className="lady-wrap">
                  <img src={LadyFinger} alt="payment" className="lady-finger" />
                </div>
                <div style={{ position: 'absolute' }}>
                  <div className="features-image-wrap">
                    <div className="features-image">
                      <img
                        src={PaymentCard}
                        alt="payment"
                        className="img-fluid card-img one"
                      />
                    </div>
                  </div>
                </div>
                <div className="lady-wrap d-none d-lg-block">
                  <img src={LadyHand} alt="payment" className="lady-hand" />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-7 col-lg-5 call-link order-1 order-lg-2">
              <ul className="d-none d-lg-block landing-steps">
                <li className="complete faded">{t('CREATE_LINK')} 🎉</li>
                <li className="complete faded">{t('SHARE_LINK')} 🎉</li>
                <li className="active"> 👉 {t('GET_PAID')} 🎉 </li>
              </ul>
              <div className="landing-steps__paragraph">
                <i>
                  <img src={WalletFeat} alt="wallet" />
                </i>
                <h2>{t('GET_PAID')}</h2>
                <p>{t('SET_RATES')}</p>
                {/* <Link
                    to="/register"
                    className="btn started-btn"
                  >
                     Get Started <i className="fas fa-chevron-right"></i>
                  </Link> */}
                <a
                  href="https://beta.web.kurobi.app/"
                  target="_blank"
                  rel="noreferrer"
                  className="btn started-btn"
                >
                  {t('GET_STARTED')} <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-five">
        <div className="container" id="features">
          <div className="row justify-content-center align-items-center">
          
            <div className="row handle-notes">
              <div className="col-md-4 col-12">
                <img src={Wallet} alt="wallet" />
                <h5>No set up fees</h5>
                <p>
                  Kurobi makes money from the service fee for paid calls and
                  does not charge a fee for free calls.
                </p>
              </div>
              <div className="col-md-4 col-12">
                <img src={VideoCamera} alt="video-camera" />
                <h5>High Quality Video</h5>
                <p>
                  Powered by WebRTC, the latest web standard, kurobi uses state
                  of the art video codecs available on the device for best
                  quality video experience. AV1, H.264.
                </p>
              </div>
              <div className="col-md-4 col-12">
                <img src={Dice} alt="scheduler" />
                <h5>Easy Scheduling</h5>
                <p>
                  Set your event availability so your guest can pick a
                  convenient time to book
                </p>
              </div>
              <div className="col-md-4 col-12">
                <img src={Record} alt="record" />
                <h5>Recording</h5>
                <p>
                  Never miss a moment. Save high quality recordings of your
                  calls on your device for later playback.
                </p>
              </div>
              <div className="col-md-4 col-12">
                <img src={Security} alt="security" />
                <h5>Encryption</h5>
                <p>
                  Video calls, messages and recordings are encrypted end-to-end
                  and can only be seen by host and the guest you're talking with
                </p>
              </div>
              <div className="col-md-4 col-12">
                <img src={Share} alt="share" />
                <h5>Screen Sharing</h5>
                <p>
                  Share your screen with your guest for enhanced communication
                  and collaboration
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="works head-banner">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-6">
              <div className="">
                <img src={SubBanner} alt="" style={{ width: '100%' }} />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="head-banner__text">
                <h1>
                  {' '}
                  <span>{t('GROW')}</span>
                  <br /> {t('YOUR')} <span>{t('AUDIENCE')}</span>
                </h1>
                <h5>{t('GET_STARTED_FOOTER_TEXT')}</h5>
                <StoreButtons />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer setLocaleToLS={setLocaleToLS} />
    </main>
  )
}

export default withSnackbar(KurobiLanding)
