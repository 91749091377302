import React from "react";
import { withSnackbar } from "notistack";
import "./style.scss";
import "./terms.scss";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

const Terms = (props) => {
  return (
    <>
      <main className="kurobi-legal terms">
        <Header />
        <div className="kurobi-legal__header">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-9">
                <div className="kurobi-legal__header__text text-center d-flex flex-column align-items-center">
                  <h5>TERMS AND CONDITIONS</h5>
                  <h3>
                    The Following Document Outlines Kurobi's Terms of Service
                  </h3>
                  <h6>Last updated: March 29, 2021</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kurobi-legal__content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div style={{ marginBottom: "2.5rem", opacity: ".75" }}>
                  <p>
                    The following are terms of accessing and/or using Kurobi’s
                    website and service (hereinafter “the Service”). By
                    accepting the terms below (hereinafter “the Agreement”)
                    and/or using the Service, you are stating that you agree to
                    be bound by all terms without modification, conditions or
                    notices.
                  </p>
                  <p>
                    Kurobi was created by, and is a service of Kurobi
                    (hereinafter “Kurobi”)
                  </p>
                </div>

                <h4>Interpretation and Definitions</h4>

                <div className="">
                  <h2>Interpretation</h2>
                  <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                </div>

                <div className="">
                  <h2>Definitions</h2>
                  <p>For the purposes of these Terms and Conditions:</p>
                  <ul>
                    <li>
                      Affiliate means an entity that controls, is controlled by
                      or is under common control with a party, where “control”
                      means ownership of 50% or more of the shares, equity
                      interest or other securities entitled to vote for election
                      of directors or other managing authority.
                    </li>
                    <li>
                      Country refers to: California, United States, Hong Kong
                    </li>
                    <li>
                      Company (referred to as either “the Company”, “We”, “Us”
                      or “Our” in this Agreement) refers to Kurobi.io, Cayman
                      Island.
                    </li>
                    <li>
                      Device means any device that can access the Service such
                      as a computer, a cellphone or a digital tablet.
                    </li>
                    <li>Service refers to the Website.</li>
                    <li>
                      Terms and Conditions (also referred as “Terms”) mean these
                      Terms and Conditions that form the entire agreement
                      between You and the Company regarding the use of the
                      Service. This Terms and Conditions agreement has been
                      created with the help of the Terms and Conditions
                      Generator.
                    </li>
                    <li>
                      Third-party Social Media Service means any services or
                      content (including data, information, products or
                      services) provided by a third-party that may be displayed,
                      included or made available by the Service.
                    </li>
                    <li>
                      Website refers to Kurobi.io, accessible from Kurobi.io
                    </li>
                    <li>
                      You mean the individual accessing or using the Service, or
                      the company, or other legal entity on behalf of which such
                      individual is accessing or using the Service, as
                      applicable.
                    </li>
                    <li>
                      USD Coin (USDC) is a stablecoin fully backed by the US
                      dollar and developed by the CENTRE consortium
                    </li>
                  </ul>
                </div>

                <div className="">
                  <h2>Acknowledgment</h2>
                  <p>
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                  </p>
                  <p>
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and Conditions, then You may not access the
                    Service.
                  </p>
                  <p>
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service.
                  </p>
                  <p>
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                  </p>
                </div>

                <div className="">
                  <h2>Scope of Kurobi services</h2>
                  <p>
                    As the provider of the Kurobi Service, Kurobi does not own,
                    create, sell, resell, provide, control, manage, offer,
                    deliver, or supply any Host Services. Hosts alone are
                    responsible for their Listings and Host Services. When
                    Members make or accept a booking, they are entering into a
                    contract directly with each other. Kurobi is not and does
                    not become a party to or other participant in any
                    contractual relationship between Members. Kurobi is not
                    acting as an agent in any capacity for any Member, except
                    for collecting Services fees, as described in this document.
                  </p>
                  <p>
                    You might be using Kurobi Service to create an individual
                    profile, and provide services, as defined in this document,
                    over paid-for video calls (referred as “Host”), or seek such
                    services (referred as “User”).
                  </p>
                  <p>
                    While we may help facilitate the resolution of disputes,
                    Kurobi has no control over and does not guarantee (i) the
                    truth or accuracy of any Listing descriptions, Ratings,
                    Reviews, or other Member Content, or (ii) the performance or
                    conduct of any Member or third party. Kurobi does not
                    endorse any Member, Listing or Host Services. Any references
                    to a Member being "verified" (or similar language) only
                    indicate that the Member has completed a relevant
                    verification or identification process and nothing else. Any
                    such description is not an endorsement, certification or
                    guarantee by Kurobi about any Member, including of the
                    Member's identity or background or whether the Member is
                    trustworthy, safe or suitable.
                  </p>
                </div>

                <div className="">
                  <h2>How you can use the Service</h2>
                  <p>
                    Your use of Kurobi requires that you have hardware, software
                    and an Internet connection fulfilling certain recommended
                    requirements, as may be specified in our Support Center. If
                    the recommended requirements are not met, you may
                    potentially still use the Service, but normally with a lower
                    quality or performance. Such reduced quality or performance
                    will not give you the right to claim any compensation from
                    Kurobi.
                  </p>
                  <p>
                    To use the Service, you will need to create a personal
                    Kurobi account. You may register an account by following the
                    instructions on the Website or other instructions prompted
                    to you. You are responsible for providing and maintaining
                    accurate and updated personal information, and for
                    safeguarding your account information. You may not select or
                    use an identity of another person with the intent to
                    impersonate that person. You must use a valid email address,
                    and Kurobi reserves the right to verify this at any time.
                    Kurobi will not be liable for any loss or damage arising
                    from your failure to comply with the above requirements.
                  </p>
                  <p>
                    Your account is strictly personal and shall not be used by
                    any other person without your supervision. Neither shall you
                    assign your account to any other person. You are in any
                    event solely responsible for the use of the Service through
                    your personal user account. We may change, terminate, or
                    restrict access to any aspect of the Service or your
                    account, at any time, without notice. We reserve the right
                    to impose limitations of use based on what we consider fair
                    or legitimate usage.
                  </p>
                  <p>
                    You are responsible for your use of Kurobi, including the
                    lawfulness of any content displayed, shared, uploaded or
                    otherwise made available by you in the Service (“the User
                    Content”).
                  </p>
                </div>

                <div className="">
                  <h2>Links to Other Websites</h2>
                  <p>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by the Company
                  </p>
                  <p>
                    The Company has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third-party web sites or services. You
                    further acknowledge and agree that the Company shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with the use of or reliance on any such content,
                    goods or services available on or through any such web sites
                    or services.
                  </p>
                  <p>
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                  </p>
                </div>

                <div className="">
                  <h2>Termination</h2>
                  <p>
                    We may terminate or suspend Your access immediately, without
                    prior notice or liability, for any reason whatsoever,
                    including without limitation if You breach these Terms and
                    Conditions.
                  </p>
                  <p>
                    Upon termination, your right to use the Service will cease
                    immediately.
                  </p>
                </div>

                <div className="">
                  <h2>“AS IS” and “AS AVAILABLE” Disclaimer</h2>
                  <p>
                    The Service is provided to You “AS IS” and “AS AVAILABLE”
                    and with all faults and defects without warranty of any
                    kind. To the maximum extent permitted under applicable law,
                    the Company, on its own behalf and on behalf of its
                    Affiliates and its and their respective licensors and
                    service providers, expressly disclaims all warranties,
                    whether express, implied, statutory or otherwise, with
                    respect to the Service, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of
                    course of dealing, course of performance, usage or trade
                    practice. Without limitation to the foregoing, the Company
                    provides no warranty or undertaking, and makes no
                    representation of any kind that the Service will meet Your
                    requirements, achieve any intended results, be compatible or
                    work with any other software, applications, systems or
                    services, operate without interruption, meet any performance
                    or reliability standards or be error free or that any errors
                    or defects can or will be corrected.
                  </p>
                  <p>
                    Without limiting the foregoing, neither the Company nor any
                    of the company’s provider makes any representation or
                    warranty of any kind, express or implied: (i) as to the
                    operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of the Company are free of
                    viruses, scripts, trojan horses, worms, malware, timebombs
                    or other harmful components.
                  </p>
                  <p>
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or all of the above exclusions
                    and limitations may not apply to You. But in such a case the
                    exclusions and limitations set forth in this section shall
                    be applied to the greatest extent enforceable under
                    applicable law.
                  </p>
                </div>

                <div className="">
                  <h2>Governing Law</h2>
                  <p>
                    The laws of the Country, excluding its conflicts of law
                    rules, shall govern this Terms and Your use of the Service.
                    Your use of the Application may also be subject to other
                    local, state, national, or international laws.
                  </p>
                </div>

                <div className="">
                  <h2>Disputes Resolution</h2>
                  <p>
                    If You have any concern or dispute about the Service, You
                    agree to first try to resolve the dispute informally by
                    contacting the Company.
                  </p>
                </div>

                <div className="">
                  <h2>For European Union (EU) Users</h2>
                  <p>
                    If You are a European Union consumer, you will benefit from
                    any mandatory provisions of the law of the country in which
                    you are resident in.
                  </p>
                </div>

                <div className="">
                  <h2>United States Legal Compliance</h2>
                  <p>
                    You represent and warrant that (i) You are not located in a
                    country that is subject to the United States government
                    embargo, or that has been designated by the United States
                    government as a “terrorist supporting” country, and (ii) You
                    are not listed on any United States government list of
                    prohibited or restricted parties.
                  </p>
                </div>

                <h4>Severability and Waiver</h4>

                <div className="">
                  <h2>Severability</h2>
                  <p>
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                  </p>
                </div>

                <div className="">
                  <h2>Waiver</h2>
                  <p>
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under these Terms
                    shall not effect a party’s ability to exercise such right or
                    require such performance at any time thereafter nor shall be
                    the waiver of a breach constitute a waiver of any subsequent
                    breach.
                  </p>
                </div>

                <div className="">
                  <h2>Translation Interpretation</h2>
                  <p>
                    These Terms and Conditions may have been translated if We
                    have made them available to You on our Service. You agree
                    that the original English text shall prevail in the case of
                    a dispute.
                  </p>
                </div>

                <div className="">
                  <h2>Changes to These Terms and Conditions</h2>
                  <p>
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is material,
                    we will make reasonable efforts to provide at least 30 days’
                    notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                  </p>
                  <p>
                    By continuing to access or use Our Service after those
                    revisions become effective, You agree to be bound by the
                    revised terms. If You do not agree to the new terms, in
                    whole or in part, please stop using the website and the
                    Service.
                  </p>
                </div>

                <div className="">
                  <h2>Restrictions on Content and Use of the Service</h2>
                  <p>
                    We also reserve the right to access, read, preserve, and
                    disclose any information as we reasonably believe is
                    necessary to (i) satisfy any applicable law, regulation,
                    legal process or governmental request, (ii) enforce the
                    Terms, including investigation of potential violations
                    hereof, (iii) detect, prevent, or otherwise address fraud,
                    security or technical issues, (iv) respond to user support
                    requests, or (v) protect our rights, property or safety, our
                    users and the public. We reserve the right to report illegal
                    activity to applicable local authorities. Prohibited User
                    Content includes, without limitation, content which:
                  </p>
                  <ul>
                    <li>
                      is offensive, such as User Content that engages in,
                      endorses or promotes racism, bigotry, discrimination,
                      hatred, harassment or physical harm of any kind against
                      any group or individual;
                    </li>
                    <li>
                      displays or links to pornographic, sexually explicit or
                      any other indecent material;
                    </li>
                    <li>
                      promotes or endorses false or misleading information or
                      illegal activities or conduct that is abusive,
                      threatening, obscene, defamatory or libelous;
                    </li>
                    <li>
                      promotes or endorses an illegal or unauthorised copy of
                      another person's copyrighted work;
                    </li>
                    <li>
                      {" "}
                      infringes on other’s trademarks, copyright or legal rights
                    </li>
                    <li>
                      contains restricted or password only access pages or
                      hidden pages or images;
                    </li>
                    <li>
                      solicits passwords or personal data from other users; or
                    </li>
                    <li>
                      violates the rights of or harms or threatens the safety of
                      other users or the Service.
                    </li>
                    <li>
                      shares Personal information about others, without their
                      consent
                    </li>
                  </ul>

                  <p>
                    Any use or reliance on any Content or materials posted via
                    the Service or obtained by you through the Service is at
                    your own risk. We do not endorse, support, represent or
                    guarantee the completeness, truthfulness, accuracy, or
                    reliability of any Content or communications posted via the
                    Services or endorse any opinions expressed via the Service.
                    You understand that by using the Service, you may be exposed
                    to Content that might be offensive, harmful, inaccurate or
                    otherwise inappropriate, or in some cases, Content that has
                    been mislabeled or is otherwise deceptive. Under no
                    circumstances will we be liable in any way for any Content,
                    including, but not limited to, any errors or omissions in
                    any Content, or any loss or damage of any kind incurred as a
                    result of the use of any Content generated or made available
                    via the Service.
                  </p>
                  <p>
                    We give you a personal, limited, worldwide, royalty-free,
                    non-assignable, non-sublicensable and non-exclusive license
                    to use the software that is provided to you by us as part of
                    the Service for your personal or business use. This license
                    is for the sole purpose of enabling you to use the Service
                    as provided by us, subject to these Terms. You may not do
                    any of the following while accessing or using the Services:
                    (i) access, tamper with, or use non-public areas of the
                    Service, our internal computer systems including technical
                    delivery systems of our subcontractors used to provide the
                    Service; (ii) probe, scan, or test the vulnerability of any
                    system or network or breach or circumvent any security or
                    authentication measures; (iii) access or search or attempt
                    to access or search the Service by any means (automated or
                    otherwise) other than through our currently available,
                    published interfaces that are provided by us (and only
                    pursuant to those terms and conditions), unless you have
                    been specifically allowed to do so in a separate agreement
                    with us; (iv) forge any TCP/IP packet header or any part of
                    the header information in any email or posting, or in any
                    way use the Service to send altered, deceptive or false
                    source-identifying information; or (v) interfere with, or
                    disrupt, (or attempt to do so), the access of any user, host
                    or network, or by scripting the creation of Content in such
                    a manner as to interfere with or create an undue burden on
                    the Service or its users.
                  </p>
                  <p>
                    The Service may contain cryptographic functionality where
                    the export of such could be restricted under applicable
                    export control laws. You shall not export or re-export the
                    Service or parts of it in violation of such laws or
                    regulations.
                  </p>
                </div>

                <div className="">
                  <h2>Service Fees</h2>
                  <p>
                    kurobi may charge fees to Hosts ("Host Fees") and/or Guests
                    ("Guest Fees") (collectively, "Service Fees") in
                    consideration for the use of the Kurobi Platform. More
                    information about when Service Fees apply and how they are
                    calculated can be found in our FAQs. Any applicable Service
                    Fees (including any applicable Taxes) will be displayed to a
                    Host or User prior to creating a call link or booking a
                    video call. Kurobi reserves the right to change the Service
                    Fees at any time, and will provide Members adequate notice
                    of any fee changes before they become effective. Such fee
                    changes will not affect any bookings made prior to the
                    effective date of the fee change.
                  </p>

                  <p>User Payment processing services for Kurobi:</p>

                  <ul>
                    <li>
                      Processing and storing of credit card data, are provided
                      by Stripe Inc. (“Stripe”) and are subject to the <a target="_blank" href="https://stripe.com/en-no/legal" rel="noreferrer">Stripe
                    Services Agreement — United States </a> (“Stripe Services
                      Agreement”). By agreeing to these terms and continuing to
                      use Kurobi, users agree to be bound by the Stripe Services
                      Agreement, as the same may be modified by Stripe from time
                      to time.
                    </li>
                    <li>
                      Crypto payment processing using Digital Assets is provided
                      by MCO Malta Dax Limited, Foris MT Limited and Foris DAX
                      Global Limited (<a target="_blank" href="https://crypto.com/" rel="noreferrer">"Crypto.com"</a>) and are subject to the <a target="_blank" href="https://crypto.com/document/mco_services" rel="noreferrer" >MCO
                      Services</a> (“MCO Services Agreement”). By agreeing to these
                      terms and continuing to use Kurobi, users agree to be
                      bound by the MCO Services Agreement, as the same may be
                      modified by MCO from time to time.
                    </li>
                  </ul>

                  <p>
                    Host payouts are processed in USDC on the Ethereum
                    Blockchain using CoinPayments Inc and Hodltech OÜ
                    (“Hodltech”) and are subject to the Terms of Service - <a rel="noreferrer" href="https://www.coinpayments.net/downloads/CoinPayments_User_Agreement.pdf" target="_blank">User
                    Agreement</a> and <a rel="noreferrer" href="https://www.coinpayments.net/help-privacy" target="_blank">Privacy Policy</a> By agreeing to these terms and
                    continuing to use Kurobi, you agree to be bound by the
                    CoinPayment Inc User Agreement as the same may be modified
                    by CoinPayment from time to time. As a condition of Kurobi
                    enabling payment processing services through CoinPayment
                    Inc, you agree to provide Kurobi accurate and complete
                    information about you and your business, and you authorize
                    Kurobi to share with CoinPayment Inc. this information and
                    transaction information related to your use of the payment
                    processing services provided by CoinPayment Inc.
                  </p>
                  <p>
                    Payment processing services for Kurobi, including the
                    processing and storing of credit card data, are provided by
                    Stripe Inc. (“Stripe”) and are subject to the <a target="_blank" href="https://stripe.com/en-no/legal" rel="noreferrer">Stripe
                    Services Agreement — United States </a> (“Stripe Services
                    Agreement”).  By agreeing to these terms and continuing to
                    use Kurobi, you agree to be bound by the Stripe Services
                    Agreement, as the same may be modified by Stripe from time
                    to time. As a condition of Kurobi enabling payment
                    processing services through Stripe, you agree to provide
                    Kurobi accurate and complete information about you and your
                    business, and you authorize Kurobi to share with Stripe Inc.
                    this information and transaction information related to your
                    use of the payment processing services provided by Stripe
                  </p>
                </div>

                <div className="">
                  <h2>Plans</h2>
                  <p>
                    Kurobi.io reserves the right to change any benefits for the
                    free, lite, advanced, and pro plans at any time for any
                    reason at our sole discretion without warning.
                  </p>
                  <p>
                    The advanced and pro packages offer 30 and 50% discounts
                    respectively. Accounts that abuse the 30 to 50% discount may
                    have their account discount restricted for further review.
                    An example of abuse on our platform would be purchasing
                    precious metals such as gold at market value with the
                    discount in effort to resell. We may define abuse on our
                    platform at any time at our sole discretion for any
                    reason.Please contact admin@kurobi.io for clarification if
                    you are making large volumes of purchases on our platform at
                    shopping.io
                  </p>
                </div>

                <div className="">
                  <h2>Contact Us</h2>
                  <p>
                    If you have any questions about these Terms and Conditions,
                    You can contact us:
                  </p>
                  <ul>
                    <li>By email: admin@kurobi.io</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default withSnackbar(Terms);
