import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../../style.scss'
import './footer.scss'
import '../../translations/i18n'

const Footer = (props) => {
  const lsLocale = localStorage.getItem('languagelocale')
  const { t } = useTranslation()

  return (
    <section className="footer">
      <div className="container">
        {/* <div className="row justify-content-center align-items-center">
          <div className="col-md-10 justify-content-center">
            <center>
              <h4>Join now and start earning on Kurobi</h4>
              <h6>
                Build and grow your community face-to-face and make some money
                while you do it.
              </h6>
              <StoreButtons />
            </center>
          </div>
        </div> */}
        <div className="row footer-row justify-content-center align-items-end">
          <div className="col-12 col-md-4 order-2 order-md-1">
            <p>
              Kurobi &copy; {new Date().getFullYear()}. {t('RIGHTS_RESERVED')}.
            </p>
            <div className="footer__social-links d-flex justify-content-center justify-content-md-start">
              <a
                className=""
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/kurobi_io"
              >
                <i className="fab fa-twitter" aria-hidden="true"></i>
              </a>

              <a
                className=""
                target="_blank"
                rel="noreferrer"
                href="https://t.me/kurobi_io"
              >
                <i className="fab fa-telegram" aria-hidden="true"></i>
              </a>
              <a
                className=""
                target="_blank"
                rel="noreferrer"
                href="https://kurobi.medium.com/"
              >
                <i className="fab fa-medium" aria-hidden="true"></i>
              </a>
              <a
                className=""
                target="_blank"
                rel="noreferrer"
                href="https://discord.gg/HbJvDfREwk"
              >
                <i className="fab fa-discord" aria-hidden="true"></i>
              </a>
              <a
                className=""
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/kurobi_io/"
              >
                <i className="fab fa-instagram" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <div className="col-12 col-md-8 order-1 order-md-2">
            <ul>
              <li>
                <a href="/#work">{t('HOW_IT_WORKS')}</a>
              </li>
              {/* <li>
                <a href="/#features">Features</a>
              </li> */}
              {/* <li>
                <Link to="/team">{t('TEAM')}</Link>
              </li> */}
q              <li>
                <Link to="/privacy">{t('PRIVACY')}</Link>
              </li>
              <li>
                <Link to="/terms">{t('TERMS')}</Link>
              </li>
              <li>
                <select
                  placeholder="Choose Language"
                  className="form-select"
                  onChange={(e) => props.setLocaleToLS(e.target.value)}
                >
                  <option value="en" selected={lsLocale === 'en'}>
                    English
                  </option>
                  <option value="zh" selected={lsLocale === 'zh'}>
                    Chinese
                  </option>
                  <option value="ja" selected={lsLocale === 'ja'}>
                    Japanese
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
