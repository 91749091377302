export const TRANSLATION_JA = {
  HOW_IT_WORKS: '機能',
  TOKEN: 'トークン',
  TEAM: 'チーム',
  GET_EARLY_ACCESS: 'アーリーアクセス版を始める',
  DISCOVER_KUROBI: 'クロビを知る',
  GET_CLOSER: 'もっと近づこう；',
  AUDIENCE: '顧客',
  AND: 'そして',
  MONITIZE: '収益化',
  YOUR_TIME_SOLANA_BLOCKCHAIN: 'Solanaブロックチェーンであなたの時間を',
  ACCEPT_BOOKING:
    '空いた時間で予約を受け付け。NFTチケットを販売してライブイベント開催。世界どこにいてもクロビで報酬を受けよう',
  LAUNCH_APP: 'アプリを立ち上げる',
  EARLY_ACCESS: 'アーリーアクセス版',
  KUROBI_ANNOUNCEMENT: 'クロビ最新情報',
  TECHNOLOGIES: 'テクノロジー',
  TAKE_YOUR_TIME_WITH_NFT_TICKETS:
    'NFTチケットで次のライブイベントをワンランクアップ',
  KUROBI_NFT_TIMEPASS:
    'フォロワーにNFTチケットを販売しよう。そしてクロビのNFTタイムパスで収益の可能性を広げよう。フォロワーにも喜んでもらおう！',
  JENNY_POLSK: 'ジェミー ポルスク',
  EVERYONE_COOKING: 'みんなでクッキング：みんなで料理してみない？',
  FORTY_FIVE_MINS: '45 分',
  FORTY_FIVE_DOLLARS: '45.00ドル',
  BOOK: '予約',
  SHARE: '共有',
  CREATE_LINK: '通話リンクを作る',
  SHARE_LINK: '通話リンクを共有',
  GET_PAID: '支払い',
  CREATE: '作る',
  CALL_LINK: '通話リンク',
  UPLOAD_VIDEO:
    '通話の内容が分かる動画をアップロードして空き時間を設定しよう。空き時間のみ予約を受け付けます。',
  GET_STARTED: '始める',
  SHARE_YOUR: '共有；',
  LINK: 'リンク',
  PUBLISH_TEXT:
    '通話リンクを公開すると、あなたの顧客はどのデバイスでも通話リンクにアクセスできるようになります。PC、マックブック、アンドロイド、iOSであなたの顧客がどこにいてもクロビアプリを使えます。',
  SET_RATES:
    'レートを設定し、ウォレットを接続するとすぐにKURO、USDC、SOLトークンで報酬を受け取れます。',
  GROW: '伸ばそう',
  YOUR: 'あなたの',
  PRIVACY: 'プライバシー',
  GET_STARTED_FOOTER_TEXT:
    'コンサルタント、医師、教師、フィットネスインストラクター、ライフコーチ、セラピスト、インフルエンサーではありませんか？世界中の顧客にリーチして空いた時間を収益化。どこにいてもクロビですぐに報酬を受け取れます。今日からブロックチェーンアプリのクロビを使ってみましょう。たった3ステップで簡単です。',
  RIGHTS_RESERVED: 'All Rights Reserved',
  TERMS: '利用規約',
  ROADMAP: 'ロードマップ',
}
