export const TRANSLATION_ZH = {
  HOW_IT_WORKS: '如何运作',
  TOKEN: '代币',
  TEAM: '团队',
  GET_EARLY_ACCESS: '抢先体验',
  DISCOVER_KUROBI: '发现 Kurobi',
  GET_CLOSER: '请靠近您的',
  AUDIENCE: '观众',
  AND: '和',
  MONITIZE: '货币化',
  YOUR_TIME_SOLANA_BLOCKCHAIN: '您的时间由 Solana Blockchain 提供',
  ACCEPT_BOOKING:
    '在您空闲时间接受预订，通过在Kurobi上出售现场活动的NFT门票，并在世界任何地方获得报酬',
  LAUNCH_APP: '启动 App',
  EARLY_ACCESS: '早期访问',
  KUROBI_ANNOUNCEMENT: '最新的 Kurobi 公告',
  TECHNOLOGIES: '技术',
  TAKE_YOUR_TIME_WITH_NFT_TICKETS: '使用 NFT 门票让您下一个现场活动更上一层楼',
  KUROBI_NFT_TIMEPASS:
    'Kurobi NFT Timepass 允许您向您的追随者销售NFT门票来扩大您盈利的潜力.为什么要融入其中，当您能带来让您的粉丝兴奋不已的不同东西时？',
  JENNY_POLSK: 'Jenny Polsk',
  EVERYONE_COOKING: '大家做饭: 让大家一起来做饭吧?',
  FORTY_FIVE_MINS: '45 分钟',
  FORTY_FIVE_DOLLARS: '$45.00',
  BOOK: '预定',
  SHARE: '分享',
  CREATE_LINK: '创建一个呼叫链接',
  SHARE_LINK: '分享您的链接',
  GET_PAID: '得到报酬',
  CREATE: '创建一个',
  CALL_LINK: '呼叫链接',
  UPLOAD_VIDEO:
    '上传一个描述您愿意谈论的内容的视频并设置您的首选可用性，Kurobi 保证只有在您空闲的时候才会收到订单。',
  GET_STARTED: '开始',
  SHARE_YOUR: '分享您的',
  LINK: '链接',
  PUBLISH_TEXT:
    '一经发布，您的呼叫链接就可供您的观众在他们的首选设备上访问 ，无论是在PC、Macbook、Android还是iOS，Kurobi都可以在您的观众所在的任何地方使用。',
  SET_RATES:
    '设置您的费率并连接您的钱包以便通过KURO, USDC, 或 SOL 代币立即收到付款。',
  GROW: '增加',
  YOUR: '您的',
  PRIVACY: '隐私',
  GET_STARTED_FOOTER_TEXT:
    '您是顾问、医生、教师、健身教练、生活教练、治疗师还是影响者？无论您身在何处和Kurobi一起，都能接触到全球观众，为您的时间赚钱，并立即获得报酬。立即加入我们的blockchain-powered App。从3个简单步骤开始',
  RIGHTS_RESERVED: '版权所有',
  TERMS: '条款',
  ROADMAP: '路线图',
}
