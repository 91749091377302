import React from "react";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";
import "./style.scss";
import "./timepass.scss";
import ManifestDecorSwitch from "./assets/switch.png";
import ManifestDecor1 from "./assets/event-prop-1.png";
import ManifestDecor2 from "./assets/event-prop-2.png";
import ManifestDecor3 from "./assets/event-prop-3.png";
import ManifestDecor4 from "./assets/event-prop-4.png";
import Sparkles from "./assets/sparkle.svg";
import TimePassLogo from "./assets/timepass.svg";

const TimePass = (props) => {
  return (
    <>
      <main className="kurobi-nftlanding">
        <div className="kurobi-nftlanding__overlay"></div>
        <div className="grained" id="grain-wrap"></div>
        <div className="background-glow d-none d-lg-flex">
          <div className="background-glow__wrap">
            <div className="background-glow__one"></div>
            <div className="background-glow__two"></div>
            <div className="background-glow__three"></div>
          </div>
        </div>
        <header className="kurobi-nftlanding__header">
          <div className="kurobi-nftlanding__header-wrap">
            <div className="kurobi-nftlanding__header__logo">
              <a href="https://kurobi.app/timepass">
                <img src={TimePassLogo} alt="" />
              </a>
            </div>
            <div className="kurobi-nftlanding__header__button">
              <Link to="/" className="btn">
                Kurobi App <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </header>
        <section className="kurobi-nftlanding__banner">
          <div className="kurobi-nftlanding__banner__content">
            <div className="kurobi-nftlanding__banner__content__text">
              <h2>
                <img
                src={ManifestDecor1}
                alt=""
                className="kurobi-nftlanding__banner__content__image kurobi-nftlanding__banner__content__image-one d-none d-lg-flex"
              />
              <img
                src={ManifestDecor2}
                alt=""
                className="kurobi-nftlanding__banner__content__image kurobi-nftlanding__banner__content__image-two d-none d-lg-flex"
              />
              <img
                src={ManifestDecor3}
                alt=""
                className="kurobi-nftlanding__banner__content__image kurobi-nftlanding__banner__content__image-three"
              />
              <img
                src={ManifestDecor4}
                alt=""
                className="kurobi-nftlanding__banner__content__image kurobi-nftlanding__banner__content__image-four d-none d-lg-flex"
              />
              <img
                src={Sparkles}
                alt=""
                className="kurobi-nftlanding__banner__content__image kurobi-nftlanding__banner__content__image-sparkle"
              />
              {/* <lottie-player
                src="https://assets6.lottiefiles.com/packages/lf20_qjq70sp1.json"
                background="transparent"
                speed="1"
                style={{ width: "95px", position: "absolute" }}
                loop
                autoplay
              ></lottie-player> */}
              <lottie-player
                src="https://assets7.lottiefiles.com/packages/lf20_5losrddo.json"
                background="transparent"
                id="lottie-anim-love"
                speed="1"
                loop
                autoplay
              ></lottie-player>
                {" "}
                <span className="start"> Start <img
                src={ManifestDecorSwitch}
                alt=""
                className="kurobi-nftlanding__banner__content__image kurobi-nftlanding__banner__content__image-switch"
              />
              </span> <span> Minting </span>{" "}
                <span>Memories</span>
              </h2>
              <p>
                Kurobi's NFT Timepass is out to reinvent the way we attend
                social events, both in-person and virtual. Minted on the
                blockchain Timepass offers organizers a seamless vehicle to sell
                tickets online as it unlocks new earning opportunities for them
                while offering their customers a long-lasting community vibe.{" "}
              </p>
              <a href="/" className="btn">
                {" "}
                <span>Lauching Soon</span>{" "}
              </a>
            </div>
          </div>
          {/* <img src={NftBackground} alt="" /> */}
        </section>
      </main>
    </>
  );
};

export default withSnackbar(TimePass);
