import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCQprYcSo2-SLZPhrMQbFyUYEn7FrczU1g",
  authDomain: "kurobi.firebaseapp.com",
  projectId: "kurobi",
  storageBucket: "kurobi.appspot.com",
  messagingSenderId: "341130495185",
  appId: "1:341130495185:web:26cb6c22c42228101cf1f0",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
