import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import "../../style.scss";
import "./header.scss";
import KurobiLogo from "../../assets/logo.svg";
import LaunchIconApp from "../../assets/launch-app-icon.svg";
import LaunchIconNft from "../../assets/launch-nft-icon.svg";
// import WhitePaper from "../../assets/whitepaper.pdf";

const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="header">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-between align-items-center">
            <div className="d-flex align-items-center col-md-auto col-4 text-dark text-decoration-none kurobi-logo">
              <Link to="/" className="">
                <img src={KurobiLogo} alt="kurobi-logo" />
                Kurobi
              </Link>
            </div>

            <div className="col-12 col-md-auto mb-2 justify-content-center mb-md-0 d-none d-md-flex">
              <ul className="nav">
                <li>
                  <a href="/#work" className="nav-link px-2 link-secondary">
                    {t("HOW_IT_WORKS")}
                  </a>
                </li>
                <li className="nav-link px-2 link-secondary">
                  <a href="/roadmap">{t("Roadmap")}</a>
                </li>

                <li className="dropdown nav-item nav-menu-dropdown">
                  <button
                    // href="#"
                    className="nav-link dropdown-toggle "
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    role="menuitem"
                  >
                    {t("Products")}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item has-heading"
                      href="https://beta.web.kurobi.app/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <header>
                            <h4>Developers</h4>
                            <span></span>
                          </header> */}
                      <div className="menu-image">
                        <img src={LaunchIconApp} alt="" />
                      </div>
                      <div className="links">
                        <span>Kurobi App</span>
                        <span>Monetize your time</span>
                      </div>
                    </a>
                    <a
                      className="dropdown-item"
                      href="/timepass"
                      // target="_blank"
                      // rel="noopener noreferrer"
                      // style={{
                      //   pointerEvents : "none",
                      //   opacity: "0.7",
                      // }}
                    >
                      <div className="menu-image">
                        <img src={LaunchIconNft} alt="" />
                      </div>
                      <div className="links">
                        <span>NFT Time Pass</span>
                        <span>Start Minting Memories</span>
                      </div>
                    </a>
                  </div>
                </li>

                {/* <li>
                <a href="/#work" className="nav-link px-2 link-secondary">{t("Products")}</a>
              </li> */}
                <li className="nav-link px-2 link-secondary">
                  <a href="/token">{t("Buy Token")}</a>
                </li>
                {/* <li className="nav-link px-2 link-secondary">
                <a
                  href="https://first-form-53f.notion.site/Kurobi-Roadmap-2021-f5ff2c70451b49c5a64a0253213cfffc"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  {t("ROADMAP")}
                </a>
              </li> */}
                <li className="nav-link px-2 link-secondary">
                  <a href="/whitepaper.pdf">{t("Whitepaper")}</a>
                </li>
                {/* <li className="nav-link px-2 link-secondary">
                  <Link to="/team">{t("TEAM")}</Link>
                </li> */}
                {/* <li>
                <a href="/#work">Become an ambassador</a>
              </li> */}
              </ul>
            </div>

            <div className="text-end col-md-auto col-lg-2 buttons-col d-none d-md-flex justify-content-around justify-content-md-end">
              <ul>
                {/* <li>
                <a
                  href="https://t.me/kurobi_io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fab fa-telegram"></i>
                </a>
              </li> */}
                <li>
                  <a
                    href="https://beta.web.kurobi.app/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="invite-button">
                      {" "}
                      {t("LAUNCH_APP")}
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </a>
                  {/* <Link
                    to="/register"
                  >
                    <button className="invite-button" > Get Early Access  </button>
                  </Link> */}
                </li>
              </ul>
            </div>
            <div className="mobile-nav-button col-auto d-md-none">
              <button
                className="btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBothOptions"
                aria-controls="offcanvasWithBothOptions"
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="mobile-nav-menu">
          <div
            className="offcanvas offcanvas-start"
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasWithBothOptions"
            aria-labelledby="offcanvasWithBothOptionsLabel"
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title"
                id="offcanvasWithBothOptionsLabel"
              >
                <Link to="/">
                  <img src={KurobiLogo} alt="kurobi-logo" />
                  Kurobi
                </Link>
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="nav flex-column">
                {/* <li>
                <a href="/#work">{t("HOW_IT_WORKS")}</a>
              </li> */}
                <li>
                  <a href="/token">{t("TOKEN")}</a>
                </li>
                <li>
                  <a href="/roadmap">{t("Roadmap")}</a>
                </li>
                {/* <li>
                  <Link to="/team">{t("TEAM")}</Link>
                </li> */}
                {/* <li>
                <a href="/#work">Become an ambassador</a>
              </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
