import React from "react";
import { withSnackbar } from "notistack";
import { PolarArea } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import Embed from "react-embed";
import Countdown from "react-countdown";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import "./style.scss";
import "./token.scss";

// import BendImage from "./assets/header-bend.svg";

// Random component
const Completionist = () => <span></span>;
// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <>
        <p>Wen KURO ?</p>
        <div className="kurobi-token__countdown">
          <span className="kurobi-token__countdown-item">
            <p>{days}</p>
            <span>Days</span>
          </span>
          :
          <span className="kurobi-token__countdown-item">
            <p>{hours}</p>
            <span>hours</span>
          </span>
          :
          <span className="kurobi-token__countdown-item">
            <p>{minutes}</p>
            <span>Mins</span>
          </span>
          :
          <span className="kurobi-token__countdown-item">
            <p>{seconds}</p>
            <span>Sec</span>
          </span>
        </div>
      </>
    );
  }
};
const dataAllocation = {
  labels: [
    "Public Token Sale",
    "DEX Listings",
    "Ecosystem Development",
    "Unlocked Ecosystem Development",
    "Rewards/Incentives",
    "Treasury",
    "Team",
  ],
  datasets: [
    {
      label: "Token Allocation",
      data: [3.34, 0.5, 54.6, 2.4, 19.16, 12, 8],
      backgroundColor: [
        "rgba(251,193,36, .4)",
        "#403a2f",
        "rgba(251,193,36, .4)",
        "#403a2f",
        "rgba(251,193,36, .4)",
        "#403a2f",
        "rgba(251,193,36, .4)",
      ],
      borderWidth: 0.2,
    },
  ],
};

defaults.font.family = "Averta-Regular";

const Token = (props) => {
  return (
    <>
      <main className="kurobi-token">
        <Header />
        <section className="kurobi-token__header">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-9">
                <div className="kurobi-token__header__text text-center d-flex flex-column align-items-center">
                  <h5>Kurobi Tokenomics</h5>
                  <p>
                    Below is the detailed breakdown of Kurobi's token economics
                  </p>
                  <div>
                    <Countdown
                      date={Date.parse("20 Sep 2021 15:00:00 GMT")}
                      renderer={renderer}
                    />
                  </div>
                  {/* <div><CountdownTimer {...countSettings} /></div> */}
                </div>
                <div className="kurobi-token__header__button text-center d-flex flex-column align-items-center">
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    How to buy $KURO token
                  </button>
                </div>
              </div>
            </div>
            {/* <img
              src={BendImage}
              className="kurobi-token__header__curve"
              alt="curve"
            ></img> */}
          </div>
        </section>
        <section className="kurobi-token__content">
          {/* <div className="kurobi-token__content__figures">
            <div className="container-xl">
              <div className="row justify-content-center">
                <div className="col-lg-9">
                  <div className="kurobi-token__content__figures-wrap">
                <div className="row gx-1">
                  <div className="col-12 col-lg">
                    <div className="kurobi-token__content__figures__block">
                      <p>Token Price</p>
                      <h4>$0.027</h4>
                    </div>
                  </div>
                  <div className="col-12 col-lg">
                    <div className="kurobi-token__content__figures__block">
                      <p>Market Cap</p>
                      <h4>$594,000</h4>
                    </div>
                  </div>
                  <div className="col-12 col-lg">
                    <div className="kurobi-token__content__figures__block">
                      <p>Total Supply</p>
                      <h4>400,000,000 KURO</h4>
                    </div>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="kurobi-token__content__tables">
            <div className="container-xl">
              <div className="kurobi-token__content__tables-wrap">
                <div className="row justify-content-center">
                  <div className="col col-lg-7">
                    <div className="kurobi-token__content__tables__table">
                      <h4>Private Sale</h4>
                      <table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Price</th>
                            <th scope="col">1KURO</th>
                            <th scope="col">0.02 USD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Max Supply</th>
                            <td>400,000,000 KURO</td>
                            <td>8,000,000 USD</td>
                          </tr>
                          <tr>
                            <th scope="row">Hard Cap (Token Sale)</th>
                            <td>80,000,000 KURO</td>
                            <td>1,600,000 USD</td>
                          </tr>
                          <tr>
                            <th scope="row">Soft Cap (Token Sale)</th>
                            <td>5,000,000 KURO</td>
                            <td>100,000 USD</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="kurobi-token__content__tables-wrap">
                <div className="row justify-content-center">
                  <div className="col col-lg-7">
                    <div className="kurobi-token__content__tables__table">
                      <h4>Public Sale</h4>
                      <table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">Price</th>
                            <th scope="col">1KURO</th>
                            <th scope="col">0.03 USD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Max Supply</th>
                            <td>400,000,000 KURO</td>
                            <td>12,000,000 USD</td>
                          </tr>
                          <tr>
                            <th scope="row">Hard Cap (Token Sale)</th>
                            <td>80,000,000 KURO</td>
                            <td>2,400,000 USD</td>
                          </tr>
                          <tr>
                            <th scope="row">Soft Cap (Token Sale)</th>
                            <td>5,000,000 KURO</td>
                            <td>150,000 USD</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="kurobi-token__content__intro">
            <div className="container-xl">
              <div className="kurobi-token__content__intro__wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="kurobi-token__content__intro__item">
                      <div className="row">
                        <div className="col">
                          <div className="kurobi-token__content__intro__item__text">
                            <p>
                              <strong>$KURO token issuance date: </strong>{" "}
                              September 20, 2021
                            </p>
                            <p>
                              <strong>SPL Token Address: </strong>{" "}
                              2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn{" "}
                              <a
                                href="https://explorer.solana.com/address/2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                <i class="fal fa-external-link-alt"></i> View on
                                explorer
                              </a>
                            </p>
                            <p>
                              <strong>Initial Supply: </strong> 24,968,491
                            </p>
                            <p>
                              <strong>Total Supply (Fixed): </strong>{" "}
                              400,000,000
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="kurobi-token__content__distribution first">
            <div className="container-xl">
              <div className="kurobi-token__content__distribution-wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="kurobi-token__content__distribution__item">
                      <div className="row align-items-stretch">
                        <div className="col">
                          <div className="kurobi-token__content__distribution__item__text">
                            <h5>Token Distribution</h5>
                            <ul>
                              <li>
                                <span>3.34%</span> (13,368,491 Kuro) to Token
                                Sale
                                {/* <ul>
                                  <li>10% released on launch</li>
                                  <li>3 months lock with 30% cliff</li>
                                  <li>
                                    linear release month to month for 9 months)
                                  </li>
                                </ul> */}
                              </li>
                              <li>
                                <span>0.5%</span> (2,000,000 Kuro) to DEX
                                Listing / Liquidity Pools{" "}
                              </li>
                              <li>
                                <span>54.6%</span> (218,400,000 Kuro) to
                                Ecosystem Development 5 years lock with
                                quarterly linear release{" "}
                              </li>
                              <li>
                                <span>2.4%</span> (9,600,000 Kuro) Unlocked
                                Ecosystem Dev
                              </li>
                              <li>
                                <span>19.16%</span> (76,631,509 Kuro)
                                Rewards/incentives/Emissions + RTS 1-year lock
                                with weekly linear release
                              </li>
                              <li>
                                <span>12%</span> (48,000,000 Kuro) Treasury 1
                                years lock
                              </li>
                              <li>
                                <span>8%</span> (32,000,000 Kuro) Team 4 years
                                lock with 10% cliff after 1 year and quarterly
                                linear release
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div
                            className="kurobi-token__content__distribution__item__image"
                            style={{ alignItems: "center" }}
                          >
                            <PolarArea data={dataAllocation} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="kurobi-token__content__distribution two">
            <div className="container-xl">
              <div className="kurobi-token__content__distribution-wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="kurobi-token__content__distribution__item">
                      <div className="row align-items-center gx-5">
                        <div className="col order-1 order-md-2">
                          <div className="kurobi-token__content__distribution__item__text">
                            <h5>Initial Circulating Supply</h5>
                            <p>
                              Kurobi has an initial token supply of 24,968,491
                              KURO. The breakdown of which is as follows:
                            </p>
                            <ul>
                              <li>
                                <span>13,368,491 $KURO</span> were sold during
                                the token’s Public Sale which ended on September
                                23, 2021
                              </li>
                            </ul>

                            <div className="kurobi-token__content__tables">
                              <div className="kurobi-token__content__tables-wrap">
                                <div className="row">
                                  <div className="col">
                                    <div className="kurobi-token__content__tables__table">
                                      <table className="table table-striped table-responsive">
                                        <thead>
                                          <tr>
                                            <th scope="col">Price</th>
                                            <th scope="col">1KURO</th>
                                            <th scope="col">0.027 USD</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th scope="row">Max Supply</th>
                                            <td>400,000,000 KURO</td>
                                            <td>20,000,000 USDC</td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              Hard Cap (Token Sale)
                                            </th>
                                            <td>13,368,491 KURO</td>
                                            <td>311,275 USDC</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <ul>
                              <li>
                                <span>2,000,000 $KURO</span> is set aside for
                                Dex Listing and Liquidity Pools
                              </li>
                              <li>
                                <span>9,600,000 $KURO</span> have been unlocked
                                to continue ecosystem development of the
                                platform
                              </li>
                            </ul>

                            <div className="kurobi-token__content__tables">
                              <div className="kurobi-token__content__tables-wrap">
                                <div className="row">
                                  <div className="col">
                                    <div
                                      className="kurobi-token__content__tables__table"
                                      style={{ marginTop: "2rem" }}
                                    >
                                      <h4>Locked Vs Circulating Supply</h4>
                                      <table className="table table-striped table-responsive">
                                        <thead>
                                          <tr>
                                            <th scope="col">Max Supply</th>
                                            <th scope="col">
                                              400,000,000 KURO
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th scope="column">
                                              Tokens Locked
                                            </th>
                                            <td>375,031,509 KURO</td>
                                          </tr>
                                          <tr>
                                            <th scope="column">
                                              Circulating Supply
                                            </th>
                                            <td>24,968,491 KURO</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="kurobi-token__content__distribution__item__text">
                              <h5>Use of funds Raised from Public</h5>
                              <ul>
                                <li>
                                  <span>40%</span> Tech Infrastructure and
                                  Development
                                </li>
                                <li>
                                  <span>25%</span> Marketing
                                </li>
                                <li>
                                  <span>20%</span> Exchange Liquidity and
                                  Listing
                                </li>
                                <li>
                                  <span>10%</span> Team expansion
                                </li>
                                <li>
                                  <span>5%</span> Legal and Compliance
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="kurobi-token__content__distribution two">
            <div className="container-xl">
              <div className="kurobi-token__content__distribution-wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="kurobi-token__content__distribution__item">
                      <div className="row align-items-center gx-5">
                        <div className="col order-1 order-md-2">
                          <div className="kurobi-token__content__distribution__item__text">
                            <h5>Unlock Schedule and Contracts</h5>
                            <p>
                              Kurobi has a carefully structured lock schedule
                              designed for the long-term sustainability of the
                              project.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="kurobi-token__content__distribution two">
            <div className="container-xl">
              <div className="kurobi-token__content__distribution-wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div className="kurobi-token__content__distribution__item">
                      <div className="row align-items-center gx-5">
                        <div className="col order-1 order-md-2">
                          <div className="kurobi-token__content__distribution__item__text">
                            <h5>Team Token</h5>
                            <p>
                              The Kurobi team, core & supporting team members,
                              get 8% of the total KURO supply. This allocation
                              is locked for a total of 4 years with 10% of this
                              allocation released after 1 year and a linear
                              quarterly release for the rest of the lock period.
                            </p>
                            <p>
                              The entire lock schedule and contracts are as
                              follows:
                            </p>

                            <p style={{ marginTop: "2rem" }}>
                              <strong
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "normal",
                                  fontFamily: '"Gilroy-bold", sans-serif',
                                }}
                              >
                                Mint Address:{" "}
                              </strong>
                              2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn
                            </p>

                            <div className="kurobi-token__content__tables">
                              <div className="kurobi-token__content__tables-wrap">
                                <div className="row">
                                  <div className="col">
                                    <div className="kurobi-token__content__tables__table">
                                      <table className="table table-striped table-responsive">
                                        <thead>
                                          <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">
                                              Destination Wallet
                                            </th>
                                            <th scope="col">Lock Tx</th>
                                            <th scope="col">Lock Date</th>
                                            <th scope="col">Amount (KURO)</th>
                                            <th scope="col">Schedule</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <th scope="row">
                                              CORE Team Account
                                            </th>
                                            <td>
                                              <p>
                                                3MeLQ6ASELvy9dpJK2C38jeZ8vsBngxvLXBv98Hsmgss
                                              </p>
                                            </td>
                                            <td>
                                              <a href="https://solscan.io/tx/tHgoYDGodvS9yLYztwij18HrbrLJBaj9ZjNaRXG6HFD5fYHhFX5pdnwXLUKh4iEeoHHb16RUqZHMchCPnnAqGRL">
                                                {" "}
                                                Solscan Link
                                              </a>
                                            </td>
                                            <td>1/10/2021 - 1/10/2025</td>
                                            <td>28,800,000</td>
                                            <td>
                                              10 %cliff and Linear quarterly
                                              release for 4yrs
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              NON-CORE Team Account
                                            </th>
                                            <td>
                                              <p>
                                                Axau1WJLyJ7TM9sqFRGaNSMPR5P7ygDdh1GL1K46pAwb
                                              </p>
                                            </td>
                                            <td>
                                              <a href="https://solscan.io/tx/NXX12Ud1XFSwPoYumPGdpRYqixty88WXgkTKRqk1h2B2LbpX623UDJB84Qem2ptsCJqNtP3KDYmb13L45gMAAdS">
                                                {" "}
                                                Solscan Link
                                              </a>
                                            </td>
                                            <td>10/10/2021 - 10/10/2025</td>
                                            <td>3,200,000</td>
                                            <td>
                                              10 %cliff and Linear quarterly
                                              release for 4yrs
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              EcoSystem Dev Accoun
                                            </th>
                                            <td>
                                              <p>
                                                45SUyZ2zws4zjseE7tBQCtgBQHrXfgyFLhw4oNrabVSd
                                              </p>
                                            </td>
                                            <td>
                                              <a href="https://solscan.io/tx/tHgoYDGodvS9yLYztwij18HrbrLJBaj9ZjNaRXG6HFD5fYHhFX5pdnwXLUKh4iEeoHHb16RUqZHMchCPnnAqGRL">
                                                {" "}
                                                Solscan Link 1
                                              </a>
                                              <a href="https://solscan.io/tx/tHgoYDGodvS9yLYztwij18HrbrLJBaj9ZjNaRXG6HFD5fYHhFX5pdnwXLUKh4iEeoHHb16RUqZHMchCPnnAqGRL">
                                                {" "}
                                                Solscan Link 2
                                              </a>
                                            </td>
                                            <td>30/09/2026</td>
                                            <td>218,400,000</td>
                                            <td>
                                              5 years lock and Quarterly linear
                                              release
                                            </td>
                                          </tr>
                                          <tr>
                                            <th scope="row">
                                              Treasury Account
                                            </th>
                                            <td>
                                              <p>
                                                82hFyW5Kunq5gTCqXqXy5VLRwTGfKmdkS9ayMZUPZdw9
                                              </p>
                                            </td>
                                            <td>
                                              <a href="https://solscan.io/tx/2LrWr5YBt7f2yHvT5uyb81x6GkGD4iXmrbfnq9gAfNm1hGE7mK7AYsRGSniJyFnsdSgtWEFX3W63YuqSHQvgzDPi">
                                                {" "}
                                                Solscan Link
                                              </a>
                                            </td>
                                            <td>10/10/2022</td>
                                            <td>48,000,000</td>
                                            <td>12 Months</td>
                                          </tr>
                                          <tr>
                                            <th scope="row">Rewards Pool</th>
                                            <td>
                                              <p>
                                                HOLD ACCOUNT:
                                                AH8NrAwzPSrG9nTqaf765UvKaJvQxtk85NuYX5bw56tB
                                              </p>
                                              <p>
                                                ORCA LOCK VAULT:
                                                DYY7eXt9jFCQusx3kDxCdUSrxZKtsV8S49BJXPewBD25
                                              </p>
                                            </td>
                                            <td>
                                              <a href="https://solscan.io/account/DYY7eXt9jFCQusx3kDxCdUSrxZKtsV8S49BJXPewBD25">
                                                {" "}
                                                Solscan Link
                                              </a>
                                            </td>
                                            <td>7/10/2021 - 7/10/2022</td>
                                            <td>76,631,509</td>
                                            <td>
                                              weekly linear release for 1 year
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="kurobi-token__content__distribution__item__text">
                              <h5
                                style={{
                                  fontSize: "1.4rem",
                                  marginBottom: "1.4rem",
                                }}
                              >
                                Revenue Structure
                              </h5>
                              <p>
                                Kurobi charges a flat fee of 10% on all
                                one-on-one calls, live events, and NFT Timepass
                                mint. The proceeds are divided into three
                                segments to be utilized as follows:
                              </p>
                              <ul>
                                <li>
                                  <span>35%</span> goes to fund the Refer2Earn
                                  Program
                                </li>
                                <li>
                                  <span>20%</span> to be used to buyback KURO
                                  tokens
                                </li>
                                <li>
                                  <span>40%</span> goes back to the platform
                                </li>
                                <li>
                                  <span>5%</span> goes to a discount programme
                                  for $KURO holders
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="kurobi-token__content__distribution three">
            <div className="container-xl">
              <div className="kurobi-token__content__distribution-wrap">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="kurobi-token__content__distribution__item">
                      <div className="row align-items-stretch">
                        <div className="col">
                          <div className="kurobi-token__content__distribution__item__text">
                            <h5>KURO Token Utility</h5>
                            <ul>
                              <li>
                                <h2>Transaction Payment</h2>
                                <p>
                                  $KURO is the primary token that unlocks the
                                  services of your favorite stars and experts
                                  from every corner of the world. You can pay
                                  and get paid instantly for calls with or send
                                  them to other users if you wish to.
                                </p>
                              </li>
                              <li>
                                <h2>NFT Tickets</h2>
                                <p>
                                  $KURO is the token that is utilized to mint
                                  Kurobi’s NFT Time Pass. These are minted NFT
                                  tickets that creators and experts can use to
                                  host live events and up their earning
                                  potential. KURO is your ticket to tomorrow’s
                                  concert or seminar.{" "}
                                </p>
                              </li>
                              <li>
                                <h2>Staking and Rewards</h2>
                                <p>
                                  Stakers can earn more $KURO tokens from a
                                  share of transaction fees paid by users on the
                                  platform. It is also the only token through
                                  which KURO affiliates get paid for referring
                                  more people to Kurobi.{" "}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-5"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div
            className="modal fade kurobi-token__modal"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header d-md-none">
                  {/* <h5 className="modal-title" id="exampleModalLabel">
                    Buy KURO On:
                  </h5> */}
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="kurobi-token__modal__video">
                    <Embed url="https://www.youtube.com/watch?v=snVqsoK8sRE" />
                  </div>
                  <div className="kurobi-token__modal__dex">
                    <h4>How To Buy (Solana DEX)</h4>

                    <div className="kurobi-token__modal__dex__steps">
                      <div className="kurobi-token__modal__dex__steps-wrap">

                        <div className="kurobi-token__modal__dex__steps__item">
                          <div className="kurobi-token__modal__dex__steps__item__number">
                            <span>1</span>
                          </div>

                          <div className="kurobi-token__modal__dex__steps__item__text">
                            <h6>
                              Make sure you have your funds converted to Solana
                              (SOL) on a centralized exchange (Coinbase,
                              Binance, FTX, Kraken, etc).{" "}
                            </h6>
                          </div>
                        </div>

                        <div className="kurobi-token__modal__dex__steps__item">
                          <div className="kurobi-token__modal__dex__steps__item__number">
                            <span>2</span>
                          </div>

                          <div className="kurobi-token__modal__dex__steps__item__text">
                            <h6>
                               Create a Solana wallet (we recommend&nbsp;<a href="https://phantom.app/" target="_blank" rel="noreferrer">Phantom</a>) and send the SOL to your Phantom wallet address.  
                            </h6>
                          </div>
                        </div>

                        <div className="kurobi-token__modal__dex__steps__item">
                          <div className="kurobi-token__modal__dex__steps__item__number">
                            <span>3</span>
                          </div>

                          <div className="kurobi-token__modal__dex__steps__item__text">
                            <h6>
                               For easy swapping, we recommend using&nbsp;<a href="https://beta.web.kurobi.app/" target="_blank" rel="noopener noreferrer">Kurobi</a> or &nbsp;<a href="https://www.orca.so/" target="_blank" rel="noopener noreferrer">Orca</a>,&nbsp;<a href="https://raydium.io/swap/" target="_blank" rel="noopener noreferrer">Raydium</a>,&nbsp;<a href="https://app.step.finance/#/swap" target="_blank" rel="noopener noreferrer">Step</a>.
                            </h6>
                          </div>
                        </div>

                        <div className="kurobi-token__modal__dex__steps__item">
                          <div className="kurobi-token__modal__dex__steps__item__number">
                            <span>4</span>
                          </div>

                          <div className="kurobi-token__modal__dex__steps__item__text">
                            <h6>
                              On any site, click the button in the top-right to 'Connect' your wallet.
                            </h6>
                          </div>
                        </div>

                        <div className="kurobi-token__modal__dex__steps__item">
                          <div className="kurobi-token__modal__dex__steps__item__number">
                            <span>5</span>
                          </div>

                          <div className="kurobi-token__modal__dex__steps__item__text">
                            <h6>
                              Now you can easily swap your SOL for KURO. Please remember to leave a small amount of SOL for transaction fees
                            </h6>
                          </div>
                        </div>

                        <div className="kurobi-token__modal__dex__steps__item">
                          <div className="kurobi-token__modal__dex__steps__item__number">
                            <span>6</span>
                          </div>

                          <div className="kurobi-token__modal__dex__steps__item__text">
                            <h6>
                              Congratulations, your KURO should be in your wallet
                            </h6>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default withSnackbar(Token);
