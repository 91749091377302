import React from 'react'
// import { Link } from "react-router-dom";
// import AppleIcon from "@material-ui/icons/Apple";
// import AndroidIcon from "@material-ui/icons/Android";
// import PlayStoreIcon from "./assets/playstore.svg";
import './store-buttons.scss'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useTranslation } from 'react-i18next'
import '../../translations/i18n'

const StoreButtons = () => {
  const { t } = useTranslation()
  return (
    <div className="buttons">
      <a href="https://beta.web.kurobi.app" target="_blank" rel="noreferrer">
        <button className="early-access">
          {t('LAUNCH_APP')} <ArrowForwardIosIcon />
        </button>
      </a>

      {/* <Link
                    to="/register"
                  >
                    <button className="early-access" > Get Early Access  </button>
                  </Link> */}

      {/* <button>
        <div>
          <AppleIcon />
        </div>
        <div>
          <p>Download on the </p>
          <p className="store">App Store</p>
        </div>
      </button>
      <button>
        <div>
          <img src={PlayStoreIcon} alt="playstore" className="playstore-icon" />
        </div>
        <div>
          <p>Download on the </p>
          <p className="store">App Store</p>
        </div>
      </button>
      <button>
        <div>
          <AndroidIcon className="android-icon" />
        </div>
        <div>
          <p>Download APK </p>
          <p className="store">android</p>
        </div>
      </button> */}
    </div>
  )
}

export default StoreButtons
